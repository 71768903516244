//===================================================
// 1.Load,Init,Settings
//===================================================
import React, { useEffect, useState } from "react";
import { DEEP_CLEAN, END_OF_LEASE } from "../../../common/Constants";

// Material-ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

// Material-ui icon
import { ExpandMore } from "@mui/icons-material";

// custom components

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const BookingSummary = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const [expanded, setExpanded] = useState("");
  const [estimatedTotalCost, setEstimatedTotalCost] = useState("");

  useEffect(() => {
    calculateTotalPayment({
      ...props,
      estimatedTotalCost,
      setEstimatedTotalCost,
    });
  }, [estimatedTotalCost, props]);

  // showMain
  return showMain({
    ...props,
    classes,
    expanded,
    setExpanded,
    estimatedTotalCost,
    setEstimatedTotalCost,
  });
};

//===================================================
// 3.Export
//===================================================
export default BookingSummary;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
BookingSummary.propTypes = {};

//4-2. defaultProps
BookingSummary.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const {
    totalRoomsStudio,
    totalHours,
    totalCost,
    expanded,
    selectedAddons,
    listGeneralAddons,
    totalCharge,
    estimatedTotalCost,
    service,
  } = props;

  return (
    <>
      <Card
        align="left"
        sx={{
          minWidth: 275,
          maxWidth: "100%",
          mb: 2,
          backgroundColor: "#f5f5f5",
          p: 2,
          borderRadius: "15px",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            color="text.secondary"
            gutterBottom
            sx={{ color: "#162d88", fontWeight: "bold" }}
          >
            Booking Summary
          </Typography>
          {/* {(service === "residential" || service === "commercial") && (
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ fontSize: 14 }}
                >
                  Total No. of Rooms
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ fontSize: 18 }}
                >
                  {totalRoomsStudio}
                </Typography>
              </Grid>
            </Grid>

          )} */}
          {selectedAddons !== "" ? (
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ fontSize: 14, fontWeight: "bold", color: "#7c7c7c" }}
                >
                  Number of Hours
                </Typography>
              </Grid>
              <Grid item sx={{ pb: 1 }}>
                {selectedAddons === "1" ||
                selectedAddons === "3" ||
                selectedAddons === "4" ||
                selectedAddons === "5" ? (
                  <TextField
                    id="outlined-number"
                    type="number"
                    value={totalHours}
                    InputProps={{
                      inputProps: {
                        min: 3,
                        max: 24,
                        style: { padding: "8px" },
                      },
                    }}
                    onChange={(event) => {
                      handleChangeHours(event.target.value, props);
                    }}
                    sx={{ width: 60 }}
                  />
                ) : (
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{ fontSize: 18, fontWeight: "bold", color: "#7c7c7c" }}
                  >
                    {totalHours}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : null}

          {selectedAddons === "1" ? (
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ fontSize: 18 }}
                >
                  Total charge:
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ fontSize: 18 }}
                >
                  {formatToCurrency(totalCharge)}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          <Divider variant="fullWidth" />
          {selectedAddons === "1" ? (
            <>
              <Typography
                gutterBottom
                variant="h6"
                color="text.secondary"
                component="div"
                sx={{ pt: 1 }}
              >
                Addons
              </Typography>
              {listGeneralAddons.map((value, index) => {
                return (
                  <Grid container alignItems="center" key={index}>
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        sx={{ fontSize: 14 }}
                      >
                        {value.text}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        sx={{ fontSize: 18 }}
                      >
                        {formatToCurrency(value.price)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          ) : null}
          <Divider variant="fullWidth" />
          <Grid container alignItems="center" sx={{ pt: 2 }}>
            <Grid item xs>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{
                  fontWeight: "bold",
                  fontSize: {
                    xs: "16px",
                    lg: "1.25rem",
                  },
                }}
              >
                Estimated Total cost:
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{
                  fontWeight: "bold",
                  fontSize: {
                    xs: "16px",
                    lg: "1.25rem",
                  },
                }}
              >
                {estimatedTotalCost}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ pt: 2 }}>
            <Grid item xs>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{
                  fontWeight: "bold",
                  fontSize: {
                    xs: "16px",
                    lg: "1.25rem",
                  },
                }}
              >
                Total cost with GST:
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{
                  fontWeight: "bold",
                  fontSize: {
                    xs: "16px",
                    lg: "1.25rem",
                  },
                }}
              >
                {totalCost}
              </Typography>
            </Grid>
          </Grid>

          {/* {selectedAddons === "1" ? ( */}
            {/* <>
              <Divider variant="fullWidth" />
              <Grid container sx={{ p: 2 }}>
                <Grid item>
                  <Typography variant="caption" display="block">
                    The total estimated cost is based on our past experience.
                    You may edit the total number of hours on what you think our
                    cleaners will do the service for you. There is a minimum of
                    3 hours of cleaning. If the cleaner spend more time, we will
                    charge accordingly. We charge $65 an hour + GST.
                  </Typography>
                </Grid>
              </Grid>
            </> */}
          {/* ) : null} */}
          
        </CardContent>
      </Card>
      {/* {selectedAddons === "" ? null : (
        <Accordion
          expanded={expanded === "service-include"}
          onChange={handleExpand("service-include", props)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="service-incude-content"
            id="service-incude"
          >
            <Typography sx={{ width: "100%", flexShrink: 0, fontWeight: 600 }}>
              The service includes:
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {selectedAddons === "0" ? (
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  Kitchen Cleaning includes:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  All Cupboard in and out, Kitchen top, bench top and range
                  hood, splash back, sink clean, scrub and shine, dust and wipe
                  where required, Floors vacuum and Mopped, Skirting board wipe.
                  Professional oven cleaning extra.
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  Bathroom Cleaning includes:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Toilet scrub, santise and shine, Shower screen cleaning, Any
                  fittings & glass cleaned, clean bathtub rinse and shine,
                  Vanity clean, Mirror clean, Exhaust fan cleaning, Skirting and
                  door wiped, Floors vacuumed and mopped, Tiles cleaning.
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  Bedroom Cleaning includes:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Lounge room, Stairs and Hallways carpet vacuumed, Floor Mop,
                  Interior Window clean, Skirting board, light switches wipe.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Carpet Cleaning, Stain & Mould Removal.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Window Cleaning includes: Windows Cleaned inside and/or window
                  frames and tracks cleaned.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We can do Gutter, driveway, house soft, window cleaning
                  (outside) , wall mark, gardening and high pressure cleaning at
                  extra cost
                </Typography>
              </Box>
            ) : null}
            {selectedAddons === "1" ? (
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  All Rooms
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Dusting and remove cobwebs. Vacuum carpets and floor. Mop all
                  floors and dry wood floors. Empty and clean ashtrays and
                  wastebaskets.
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  Kitchen
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Wipe and sanitise countertops and backsplashes. Clean the
                  range top and refrigerator top and exterior. Mop floors.
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  Bathrooms
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Clean, scrub and sanitise showers, bathtubs and sinks. Clean
                  and sanitise vanities, backsplashes and toilets. Clean
                  mirrors. Polish chrome. Mop floors and tile walls. Deodorise.
                </Typography>
              </Box>
            ) : null}
            {selectedAddons === "2" ? (
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  Steam Cleaning includes:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  The use of steam to carry a cleaning agent deep into the
                  carpet. The process starts with spraying a pre-cleaning agent
                  that loosens dirt and soiling - making it easy to steam clean
                  away any dirt and stains.
                </Typography>
              </Box>
            ) : null}
          </AccordionDetails>
        </Accordion>
      )} */}
      {/* <Accordion
        expanded={expanded === "note"}
        onChange={handleExpand("note", props)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="note-content"
          id="note"
        >
          <Typography sx={{ width: "100%", flexShrink: 0, fontWeight: 600 }}>
            Notes:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            Our price covers the cost of labour, equipment, supplies, insurance,
            profit margin, fees and management utilised in delivering the
            cleaning service to you.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please informed us about nearby parking spot otherwise we charge for
            parking fees.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Other services such as outside window cleaning, carpet steam
            cleaning, oven cleaning are always extras. We will charge extra
            accordingly.
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you wish to cancel or postpone the booked appointment, give us a
            call or message us immediately or we charge cancellation fee of $50.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion
        expanded={expanded === "members"}
        onChange={handleExpand("members", props)}
        sx={{ mb: 3 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="members-content"
          id="members"
        >
          <Typography sx={{ width: "80%", flexShrink: 0, fontWeight: 600 }}>
            Cleaningly Exclusive Club members enjoy:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            Priority bookings
          </Typography>
          <Typography variant="body1" gutterBottom>
            Priority Customer support handling
          </Typography>
          <Typography variant="body1" gutterBottom>
            Discounted Member rates
          </Typography>
          <Typography variant="body1" gutterBottom>
            Exclusive deals
          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </>
  );
};

/**
 * Calculate Payment
 * @param {*} props
 */
const calculateTotalPayment = (props) => {
  const {
    totalHours,
    setTotalCost,
    totalRoomsStudio,
    selectedAddons,
    listGeneralAddons,
    setTotalCharge,
    setTotalHours,
    setEstimatedTotalCost,
    bookingDate,
  } = props;

  // TODO: need to dynamic the 55 price per hour
  let totalToPay = totalHours * 55;
  if (selectedAddons === "1") {
    let generalCleaning = 55;
    if (bookingDate) {
      const d = new Date(bookingDate);
      let day = d.getDay();

      if (day === 0) {
        generalCleaning = 65;
      }
      if (day === 6) {
        generalCleaning = 60;
      }
    }
    totalToPay = totalHours * generalCleaning;
  }

  setTotalCost(formatToCurrency(totalToPay));

  if (selectedAddons === "7") {
    totalToPay = totalHours * 65;
    const gst = totalToPay * 0.1;
    setEstimatedTotalCost(formatToCurrency(totalToPay));
    setTotalCost(formatToCurrency(totalToPay + gst));
  }

  if (selectedAddons === "0") {
    const numberOfStudio = END_OF_LEASE.find(
      (val) => val.num === totalRoomsStudio
    );
    setTotalHours(totalRoomsStudio * 3);
    const gst = numberOfStudio.price * 0.1;
    setEstimatedTotalCost(formatToCurrency(numberOfStudio.price));
    setTotalCost(formatToCurrency(numberOfStudio.price + gst));
  }

  if (selectedAddons === "6") {
    const numberOfStudio = DEEP_CLEAN.find(
      (val) => val.num === totalRoomsStudio
    );

    setTotalHours(totalRoomsStudio * 3);
    const gst = numberOfStudio.price * 0.1;
    setEstimatedTotalCost(formatToCurrency(numberOfStudio.price));
    setTotalCost(formatToCurrency(numberOfStudio.price + gst));
  }

  if (selectedAddons === "1") {
    let totalAddons = listGeneralAddons.reduce(
      (previousValue, currentValue) => previousValue + currentValue.price,
      0
    );
    const newTotalToPay = totalToPay + totalAddons;
    const gst = newTotalToPay * 0.1;
    setEstimatedTotalCost(formatToCurrency(newTotalToPay));
    setTotalCost(formatToCurrency(newTotalToPay + gst));
    setTotalCharge(totalToPay);
  }

  if (selectedAddons === "2") {
    const carpetChargePerRoom = {
      2: 120,
      3: 180,
      4: 240,
      5: 300,
    };
    const charge = carpetChargePerRoom[totalRoomsStudio];
    const gst = charge * 0.1;
    setEstimatedTotalCost(formatToCurrency(charge));
    setTotalCost(formatToCurrency(charge + gst));
  }

  if (selectedAddons === "3") {
    totalToPay = totalHours * 65;
    const gst = totalToPay * 0.1;
    setEstimatedTotalCost(formatToCurrency(totalToPay));
    setTotalCost(formatToCurrency(totalToPay + gst));
  }

  if (selectedAddons === "4") {
    totalToPay = totalHours * 65;
    const gst = totalToPay * 0.1;
    setEstimatedTotalCost(formatToCurrency(totalToPay));
    setTotalCost(formatToCurrency(totalToPay + gst));
  }

  if (selectedAddons === "5") {
    totalToPay = totalHours * 65;
    const gst = totalToPay * 0.1;
    setEstimatedTotalCost(formatToCurrency(totalToPay));
    setTotalCost(formatToCurrency(totalToPay + gst));
  }

  if (selectedAddons === "8") {
    totalToPay = totalHours * 65;
    const gst = totalToPay * 0.1;
    setEstimatedTotalCost(formatToCurrency(totalToPay));
    setTotalCost(formatToCurrency(totalToPay + gst));
  }

  if (selectedAddons === "9") {
    totalToPay = totalHours * 65;
    const gst = totalToPay * 0.1;
    setEstimatedTotalCost(formatToCurrency(totalToPay));
    setTotalCost(formatToCurrency(totalToPay + gst));
  }

  if (selectedAddons === "10") {
    totalToPay = totalHours * 65;
    const gst = totalToPay * 0.1;
    setEstimatedTotalCost(formatToCurrency(totalToPay));
    setTotalCost(formatToCurrency(totalToPay + gst));
  }
};

/**
 * Format amount
 * @param {*} amount
 * @returns
 */
const formatToCurrency = (amount) => {
  return "$" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

//===================================================
// 6.Actions
//===================================================

/**
 *
 * @param {*} panel
 * @param {*} props
 * @returns
 */
const handleExpand = (panel, props) => (event, newExpanded) => {
  const { setExpanded } = props;
  setExpanded(newExpanded ? panel : false);
};

const handleChangeHours = (hours, props) => {
  const { setTotalHours } = props;
  if (hours < 3) {
    setTotalHours(3);
  } else if (hours > 24) {
    setTotalHours(24);
  } else {
    setTotalHours(hours);
  }
};
