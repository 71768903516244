import { useEffect, useState } from "react";
// Material-ui
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SignaturePad from "react-signature-canvas";
import styles from "./styles.module.css";
const ChecklistCompleteConfirmation = (props) => {
  const { open, onClose, id, onConfirmation } = props;
  const [sigPad, setSigPad] = useState(null);
  const [emptyError, setEmptyError] = useState(false);

  const onSubmit = () => {
    if (sigPad?.isEmpty()) {
      setEmptyError(true);
      return;
    }

    try {
      sigPad?.getTrimmedCanvas().toBlob((blob) => {
        let file = new File([blob], `signature-${id}.png`, {
          type: "image/png",
        });
        onConfirmation(file);
      }, "image/png");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Confirm Checklist
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Button onClick={onClose}>Close</Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">Sign Here</Typography>
        <Box
          sx={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            maxWidth="md"
            sx={{
              height: "400px",
            }}
          >
            <SignaturePad
              canvasProps={{ className: styles.sigPad }}
              ref={(ref) => {
                setSigPad(ref);
              }}
              onBegin={() => setEmptyError(false)}
            />
            <Button
              variant="outlined"
              onClick={() => sigPad?.clear()}
              fullWidth
              size="small"
            >
              Clear
            </Button>
            {emptyError && (
              <Box sx={{ mb: 10 }}>
                <Typography variant="h6" color="red">
                  Signature is required.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ mt: emptyError ? 15 : 10 }}>
          <Button variant="contained" onClick={onSubmit} fullWidth>
            Confirm I have checked all items on the checklist
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChecklistCompleteConfirmation;
