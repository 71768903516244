//===================================================
// 1.Load,Init,Settings
//===================================================
import { format } from "date-fns";
import React, { useState } from "react";

// Material-ui
import { Box, Container, FormLabel, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

// custom components

// css *required
import { useEffect } from "react";
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const CustomDateTimePicker = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const currentDate = new Date();
  const currentHour = currentDate.getHours();

  let addDate = 1;
  if (currentHour >= 23) {
    addDate = 2;
  }

  currentDate.setDate(currentDate.getDate() + addDate);
  const defaultDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    7,
    0
  );
  const [value, setValue] = useState(defaultDate);

  useEffect(() => {
    if (value) {
      props.handleShowTypeOfArea(props);
    }
  }, [value, props]);
  // showMain
  return showMain({
    ...props,
    classes,
    value,
    setValue,
    defaultDate,
  });
};

//===================================================
// 3.Export
//===================================================
export default CustomDateTimePicker;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
CustomDateTimePicker.propTypes = {};

//4-2. defaultProps
CustomDateTimePicker.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { value, defaultDate, classes } = props;
  console.log(value);
  return (
    <React.Fragment>
      <Container
        align="left"
        sx={{ mt: 1 }}
        className={classes.containerLabel}
        maxWidth={false}
      >
        <FormLabel
          id="input-place"
          sx={{
            fontSize: { xs: 14, sm: 14, md: 16, lg: 16, xl: 16 },
            fontWeight: "bold",
          }}
        >
          When do you want us to Clean?
        </FormLabel>
      </Container>

      <Box
        display="flex"
        justifyContent="start"
        sx={{
          backgroundColor: "white",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "15px",
          },
          borderRadius: "15px",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDateTimePicker
            sx={{
              "& .PrivateDateTimePickerToolbar-penIcon": {
                display: "none !important",
              },
            }}
            value={value}
            onChange={(newValue) => {
              showNextStep(newValue, props);
            }}
            ampm={true}
            minDate={defaultDate}
            minTime={new Date(0, 0, 0, 7, 0)}
            maxTime={new Date(0, 0, 0, 20, 0)}
            timeIntervals={30}
            inputFormat="d MMMM yyyy h:mm a"
            mask="___/__/__ __:__ _M"
            renderInput={(params) => (
              <TextField sx={{ borderRadius: "15px" }} {...params} fullWidth />
            )}
          />
        </LocalizationProvider>
        {/* {!isShowTypeOfArea ? (
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            onClick={() => handleShowTypeOfArea && handleShowTypeOfArea()}
            disableElevation
          >
            Continue
          </Button>
        ) : null} */}
      </Box>
    </React.Fragment>
  );
};

//===================================================
// 6.Actions
//===================================================

/**
 * Show Nextstep Button
 * @param {*} message
 * @param {*} props
 */
const showNextStep = (selectedDateTime, props) => {
  const {
    handleShowTypeOfArea,
    setValue,
    isShowTypeOfArea,
    setSelectedDateTime,
  } = props;
  setValue(selectedDateTime);
  setSelectedDateTime(format(new Date(selectedDateTime), "yyyy-MM-dd hh:mm a"));
  if (!isShowTypeOfArea) {
    handleShowTypeOfArea && handleShowTypeOfArea();
  }
};
