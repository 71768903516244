//===================================================
// 1.Load,Init,Settings
//===================================================
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toUpperCaseFirstLetter } from "../../../common/Utility";
import {
  GetAuthValues,
  GetOutsideAuthActions,
  setIsLoginSuccess as setIsLoginSuccessAction,
} from "../../../redux/modules/Auth";
import { setSelectedAddress as setSelectedAddressAction } from "../../../redux/modules/Book";

// Material-ui
import {
  AppBar,
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";

// custom components
import { CreateAccountModal } from "..";
import { CompanyLogo, LoginModal } from "../";
import CustomSnackBarMessage from "../CustomSnackBarMessage";

// css *required
import { useStyles } from "./index.css";

import { useNavigate } from "react-router-dom";
//===================================================
// 2.Main Component
//===================================================
const NavBar = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();
  const navigate = useNavigate();
  // 2-2. Store
  const { accessToken, userInfo } = GetAuthValues();
  const dispatch = useDispatch();
  const [isShowCreateAccountModal, setIsShowCreateAccountModal] =
    useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [userType, setUserType] = useState(null);
  const [snackBarData, setSnackBarData] = useState({
    isShow: false,
    message: "",
    severity: "success",
  });

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const setSelectedAddress = (address) =>
    dispatch(setSelectedAddressAction(address));
  const isLoginSuccess = useSelector((state) => state.auth.isLoginSuccess);
  const setIsLoginSuccess = (value) => setIsLoginSuccessAction(value);

  const open = Boolean(showMenu);
  // showMain
  return showMain({
    ...props,
    classes,
    isShowCreateAccountModal,
    setIsShowCreateAccountModal,
    accessToken,
    dispatch,
    showMenu,
    setShowMenu,
    open,
    userType,
    setUserType,
    snackBarData,
    setSnackBarData,
    userInfo,
    isLoginModalOpen,
    setIsLoginModalOpen,
    setSelectedAddress,
    isLoginSuccess,
    setIsLoginSuccess,
    navigate,
  });
};

//===================================================
// 3.Export
//===================================================
export default NavBar;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
NavBar.propTypes = {};

//4-2. defaultProps
NavBar.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const {
    classes,
    isShowCreateAccountModal,
    setIsShowCreateAccountModal,
    accessToken,
    showMenu,
    setShowMenu,
    open,
    userType,
    snackBarData,
    setSnackBarData,
    userInfo,
    isLoginModalOpen,
    setIsLoginModalOpen,
    setSelectedAddress,
    isLoginSuccess,
    setIsLoginSuccess,
  } = props;
  return (
    <>
      <LoginModal
        open={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        setIsLoginSuccess={() => setIsLoginSuccess(!isLoginSuccess)}
        setAddress={(address) => setSelectedAddress(address)}
      />
      <CustomSnackBarMessage
        open={snackBarData.isShow}
        handleClose={() => {
          setSnackBarData({ ...snackBarData, isShow: false });
        }}
        message={snackBarData.message}
        severity={snackBarData.severity}
        variant="filled"
      />
      <CreateAccountModal
        open={isShowCreateAccountModal}
        onClose={() => setIsShowCreateAccountModal(false)}
        setSnackBar={(data) => {
          setSnackBarData({ ...snackBarData, ...data });
        }}
        userType={userType}
      />
      <Box sx={{ flexGrow: 1, margin: "auto" }}>
        {/* <Container  maxWidth={false}> */}
        <AppBar
          position="static"
          color="inherit"
          className={classes.root}
          sx={{
            paddingLeft: {
              xs: 1,
              sm: 2,
              md: 3,
              lg: 4,
              xl: 5,
            },
            paddingRight: {
              xs: 1,
              sm: 2,
              md: 3,
              lg: 4,
              xl: 5,
            },
            backgroundColor: "#162d88",
          }}
        >
          <Toolbar>
            <CompanyLogo />
            <>
              {!accessToken && (
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  sx={{
                    //  margin: "auto",
                    fontWeight: "bold",
                    fontSize: {
                      xs: 9,
                      sm: 10,
                      md: 11,
                      lg: 12,
                      xl: 14,
                    },
                    mr: {
                      xs: 1,
                      sm: 3,
                      md: 4,
                    },
                    marginLeft: {
                      lg: 34,
                    },
                    backgroundColor: "#ffb800",
                    px: {
                      xs: 2,
                      sm: 3,
                      md: 5,
                    },
                    py: {
                      xs: 1,
                      sm: 1,
                      md: 2,
                    },
                    borderRadius: "40px",
                    color: "#333",
                    whiteSpace: "nowrap",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setIsLoginModalOpen(true);
                  }}
                >
                  Log In
                </Link>
              )}
              <Button
                color="primary"
                className={classes.navButton}
                onClick={(event) => handleShowMenu(event, props)}
                sx={{
                  // margin: "auto",
                  fontWeight: "bold",
                  fontSize: {
                    xs: 9,
                    sm: 10,
                    md: 11,
                    lg: 12,
                    xl: 14,
                  },
                  px: {
                    xs: 2,
                    sm: 3,
                    md: 5,
                  },
                  py: {
                    xs: 1,
                    sm: 1,
                    md: 2,
                  },
                  borderRadius: "40px",
                  border: "2px solid white",
                  color: "white",
                  whiteSpace: "nowrap",
                  textTransform: "none",
                }}
              >
                {accessToken
                  ? `Welcome, ${toUpperCaseFirstLetter(userInfo.firstname)}`
                  : "Sign Up"}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={showMenu}
                open={open}
                onClose={() => setShowMenu(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {accessToken ? (
                  <div>
                    <MenuItem onClick={() => handleProfile(props)}>
                      Profile
                    </MenuItem>
                    <MenuItem onClick={() => handleLogout(props)}>
                      Logout
                    </MenuItem>
                  </div>
                ) : (
                  <div>
                    <MenuItem onClick={() => handleRegisterClient(props)}>
                      Client
                    </MenuItem>
                    <MenuItem onClick={() => handleRegisterCleaner(props)}>
                      Professional Cleaner
                    </MenuItem>
                  </div>
                )}
              </Menu>
            </>
          </Toolbar>
        </AppBar>
        {/* </Container> */}
      </Box>
    </>
  );
};

//===================================================
// 6.Actions
//===================================================

/**
 * handle logout
 * @param {*} props
 */
const handleLogout = (props) => {
  const { dispatch, setShowMenu } = props;
  setShowMenu(null);
  const { logout } = GetOutsideAuthActions();
  logout(dispatch);
};

/**
 * Show menu in create account
 * @param {*} e
 * @param {*} props
 */
const handleShowMenu = (event, props) => {
  const { setShowMenu } = props;
  setShowMenu(event.currentTarget);
};

/**
 * Show register client modal
 * @param {*} props
 */
const handleRegisterClient = (props) => {
  const { setUserType, setShowMenu, setIsShowCreateAccountModal } = props;
  setUserType("client");
  setIsShowCreateAccountModal(true);
  setShowMenu(null);
};

/**
 * Show register cleaner modal
 * @param {*} props
 */
const handleRegisterCleaner = (props) => {
  const { setUserType, setShowMenu, setIsShowCreateAccountModal } = props;
  setUserType("cleaner");
  setIsShowCreateAccountModal(true);
  setShowMenu(null);
};

/**
 * Handle redirect profile
 * @param {*} props
 */

const handleProfile = ({ navigate }) => {
  navigate("/profile");
};
