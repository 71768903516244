//===================================================
// 1.Load,Init,Settings
//===================================================
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginBy } from "../../../common/Utility";
import {
  GetAuthValues,
  GetOutsideAuthActions,
} from "../../../redux/modules/Auth";

// Material-ui
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";

// Material-ui icon
import { LockClockOutlined } from "@mui/icons-material";

// custom components
import CustomPasswordInput from "../CustomPasswordInput";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const LoginModal = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const dispatch = useDispatch();
  const { userInfo, isLoading, error, accessToken, address } = GetAuthValues();
  const [hasError, setHasError] = useState({});
  // showMain
  return showMain({
    ...props,
    classes,
    loginForm,
    setLoginForm,
    userInfo,
    address,
    isLoading,
    error,
    accessToken,
    dispatch,
    hasError,
    setHasError,
  });
};

//===================================================
// 3.Export
//===================================================
export default LoginModal;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
LoginModal.propTypes = {};

//4-2. defaultProps
LoginModal.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { open, onClose, loginForm, setLoginForm, hasError, isLoading } = props;
  return (
    <Dialog onClose={onClose} open={open} maxWidth="xs" fullWidth>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#00AEEF" }}>
            <LockClockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={(e) => handleOnSubmit(e, props)}
            noValidate
            sx={{
              mt: 1,
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "15px",
              },
              zIndex: "1051 !important",
            }}
          >
            <TextField
              margin="normal"
              error={hasError.auth ? true : false}
              type="text"
              name="email"
              sx={{ backgroundColor: "white", borderRadius: "15px" }}
              autoComplete="email"
              value={loginForm.email}
              label="Email or phone"
              fullWidth
              autoFocus
              onChange={(e) =>
                setLoginForm({ ...loginForm, email: e.target.value })
              }
              required
            />
            <CustomPasswordInput
              margin="normal"
              error={hasError.auth ? true : false}
              helperText={hasError.auth}
              name="password"
              value={loginForm.password}
              label="Password"
              autoComplete="current-password"
              fullWidth
              onChange={(e) =>
                setLoginForm({ ...loginForm, password: e.target.value })
              }
              required
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              {isLoading ? (
                <CircularProgress
                  disableShrink={true}
                  size={25}
                  color="inherit"
                />
              ) : (
                "SIGN IN"
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

//===================================================
// 6.Actions
//===================================================

/**
 * handle submit lgin
 * @param {*} e
 * @param {*} props
 */
const handleOnSubmit = async (e, props) => {
  e.preventDefault();
  const {
    loginForm,
    setLoginForm,
    dispatch,
    onClose,
    setHasError,
    setIsLoginSuccess,
  } = props;
  const credentials = {
    email: loginBy(loginForm.email),
    password: loginForm.password,
  };
  const { loginUser } = GetOutsideAuthActions();
  const response = await loginUser(credentials, dispatch);

  if (response.type === "auth/failure") {
    setHasError({ auth: "Invalid credentials" });
    return;
  }
  onClose && onClose();
  setIsLoginSuccess && setIsLoginSuccess();
  setHasError(false);
  setLoginForm({ email: "", password: "" });
  // setAddress(response.payload.address[0].fullAddress);
};
