// ** React Imports
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// ** MUI Imports
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { ChecklistCompleteConfirmation } from "../../atoms";

const serverUrl = process.env.REACT_APP_BACKEND_URL;
axios.defaults.baseURL = serverUrl;

const PartnerChecklist = () => {
  const { id } = useParams();
  const [checklist, setChecklist] = useState(null);
  const [checklistCompleted, setChecklistConfirmed] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInfo = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/booking/${id}`);
        setChecklist(response?.data?.data?.checklist);
        setChecklistConfirmed(response?.data?.data?.checklistCompleted);

        if (!response?.data?.data?._id) {
          navigate("/404");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchInfo();
  }, [id, navigate]);

  const refetchInfo = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/booking/${id}`);
      setChecklist(response?.data?.data?.checklist);
      setChecklistConfirmed(response?.data?.data?.checklistCompleted);

      if (!response?.data?.data?._id) {
        navigate("/404");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmation = async (file) => {
    const formData = new FormData();
    formData.append("signature", file);
    try {
      await axios.patch(`/api/booking/checklist-completed/${id}`, formData);
      handleCloseConfirmation();
      refetchInfo();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container sx={{ mt: 5 }}>
      <Card sx={{ px: { xs: "4", l: "15" } }}>
        <CardContent>
          {isLoading ? (
            <Typography>Loading....</Typography>
          ) : (
            <>
              {" "}
              {checklist && (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">Partner Checklist</Typography>
                  </Grid>
                  <Grid item xs={12} mt={3}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {checklist}
                    </Typography>
                  </Grid>

                  {!checklistCompleted ? (
                    <Grid item xs={12} mt={5}>
                      <Button
                        variant="contained"
                        onClick={handleOpenConfirmation}
                      >
                        I have checked all items on the checklist
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={12} mt={5}>
                      <Typography>Status: Completed</Typography>
                    </Grid>
                  )}
                </Grid>
              )}
              {!checklist && <Typography>Checklist not found</Typography>}
            </>
          )}
        </CardContent>
      </Card>
      <ChecklistCompleteConfirmation
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        id={id}
        onConfirmation={onConfirmation}
      />
    </Container>
  );
};

export default PartnerChecklist;
