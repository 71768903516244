//===================================================
// 1.Load,Init,Settings
//===================================================
import React, { useEffect } from "react";
import { ASSIGNED_SUBURB_FOR_STATE, STATE } from "../../../common/Constants";

// Material-ui
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

// custom components

// css *required
import { getLatLong } from "../../../common/GeoCode";
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const MandualAddressForm = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  // const [addressForm, setAddressForm] = useState({
  //   street: "",
  //   zipcode: "",
  //   state: "",
  //   suburb: "",
  //   // fullAddress: "",
  //   country: "Australia",
  // });
  const { state, setAddress } = props;

  useEffect(() => {
    const getCoordinates = async () => {
      try {
        if (state !== "") {
          const { lat, lng } = await getLatLong(
            `${ASSIGNED_SUBURB_FOR_STATE[state]}`
          );
          setAddress({ latitude: lat, longitude: lng });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCoordinates();
  }, [state]);

  // showMain
  return showMain({
    ...props,
    classes,
  });
};

//===================================================
// 3.Export
//===================================================
export default MandualAddressForm;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
MandualAddressForm.propTypes = {};

//4-2. defaultProps
MandualAddressForm.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { hasError, state, street, zipcode, suburb } = props;
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "15px",
          },
        }}
      >
        <TextField
          error={hasError.street ? true : false}
          helperText={hasError.street}
          type="text"
          name="street"
          label="Street Address"
          value={street}
          sx={{ backgroundColor: "white", borderRadius: "15px" }}
          onChange={(event) => {
            handleInputChange(event, props);
          }}
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "15px",
          },
        }}
      >
        <TextField
          error={hasError.suburb ? true : false}
          helperText={hasError.suburb}
          type="text"
          name="suburb"
          value={suburb}
          sx={{ backgroundColor: "white", borderRadius: "15px" }}
          onChange={(event) => {
            handleInputChange(event, props);
          }}
          label="Suburb"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <FormControl
          fullWidth
          error={hasError.state ? true : false}
          sx={{ borderRadius: "15px" }}
        >
          <InputLabel id="state">State</InputLabel>
          <Select
            labelId="state"
            id="state"
            name="state"
            value={state}
            sx={{ backgroundColor: "white", borderRadius: "15px" }}
            label="State"
            onChange={(event) => {
              handleInputChange(event, props);
            }}
          >
            {STATE.map((state) => (
              <MenuItem key={state.short_name} value={state.short_name}>
                {`${state.long_name} (${state.short_name.toLocaleUpperCase()})`}
              </MenuItem>
            ))}
          </Select>
          {hasError.state ? (
            <FormHelperText>Please select a state</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "15px",
          },
        }}
      >
        <TextField
          error={hasError.zipcode ? true : false}
          helperText={hasError.zipcode}
          type="text"
          name="zipcode"
          value={zipcode}
          sx={{ backgroundColor: "white", borderRadius: "15px" }}
          onChange={(event) => {
            handleInputChange(event, props);
          }}
          label="Postcode"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

//===================================================
// 6.Actions
//===================================================

const handleInputChange = async (event, props) => {
  const { setAddress, street, suburb, state, zipcode } = props;
  let name = event.target.name;
  let value = event.target.value;
  setAddress({
    [name]: value,
    fullAddress: `${street}, ${suburb} ${state} ${zipcode}, Australia`,
    country: "Australia",
  });
};
