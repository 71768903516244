//===================================================
// 1.Load,Init,Settings
//===================================================
import React from "react";

// Material-ui
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    Link,
    TextField,
    Typography,
} from "@mui/material";

// custom components
import { CustomPhoneField } from "../../atoms";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const UserInformationForm = (props) => {
    // 2-1. useStyles *require
    const classes = useStyles();

    // 2-2. Store

    // showMain
    return showMain({
        ...props,
        classes,
    });
};

//===================================================
// 3.Export
//===================================================
export default UserInformationForm;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
UserInformationForm.propTypes = {};

//4-2. defaultProps
UserInformationForm.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
    const {
        classes,
        isLoading,
        isShowSearch,
        hasError,
        setTermsAndPolicy,
        userInfo,
        disabled,
        handleAffiliateOpen
    } = props;

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleOnSubmit(props);
            }}
        >
            <Box
                sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "15px",
                    },
                    borderRadius: "15px",
                }}
            >
                <TextField
                    error={hasError.email ? true : false}
                    type="text"
                    label="Preferred Email"
                    variant="outlined"
                    name="email"
                    value={userInfo.email}
                    onChange={(e) => handleInputChange(e.target, props)}
                    sx={{ mb: 2, backgroundColor: "white", borderRadius: "15px" }}
                    fullWidth
                    helperText={hasError.email}
                    inputProps={{ readOnly: disabled }}
                />
            </Box>
            <Grid
                container
                rowSpacing={2}
                sx={{ p: 0 }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "15px",
                        },
                        borderRadius: "15px",
                    }}
                >
                    <TextField
                        error={hasError.firstName ? true : false}
                        label="Firstname"
                        variant="outlined"
                        name="firstName"
                        sx={{ backgroundColor: "white", borderRadius: "15px" }}
                        value={userInfo.firstName}
                        onChange={(e) => handleInputChange(e.target, props)}
                        fullWidth
                        helperText={hasError.firstName}
                        inputProps={{ readOnly: disabled }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "15px",
                        },
                        borderRadius: "15px",
                    }}
                >
                    <TextField
                        error={hasError.lastName ? true : false}
                        label="Lastname"
                        variant="outlined"
                        name="lastName"
                        sx={{ backgroundColor: "white", borderRadius: "15px" }}
                        value={userInfo.lastName}
                        onChange={(e) => handleInputChange(e.target, props)}
                        fullWidth
                        helperText={hasError.lastName}
                        inputProps={{ readOnly: disabled }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        "& .form-control ": {
                            borderRadius: "15px",
                        },
                        borderRadius: "15px",
                        mb: 5
                    }}
                >
                    <CustomPhoneField
                        setPhoneNumber={(val) =>
                            handleInputChange({ name: "phone", value: val }, props)
                        }
                        hasError={hasError.phoneNumber}
                        loginUserPhone={userInfo.phone}
                    />
                </Grid>
            </Grid>
            { !isShowSearch ? (
                <Button
                    type="submit"
                    float="right"
                    variant="contained"
                    sx={{
                        backgroundColor: "#ffb800",
                        borderRadius: "40px",
                        color: "#333",
                        fontWeight: "bold",
                        py: 2,
                        mt: 2,
                    }}
                    disableElevation
                    fullWidth
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <CircularProgress disableShrink={true} size={25} color="inherit" />
                    ) : "Continue"}
                </Button>
            ) : null}
        </form>
    );
};

//===================================================
// 6.Actions
//===================================================

/**
 * submit booking
 * @param {*} props
 */
const handleOnSubmit = (props) => {
    const { handleSubmitUserInfo, isShowSearch, hasError } = props;

    handleSubmitUserInfo && handleSubmitUserInfo();
};

const handleInputChange = (input, props) => {
    const { setInformation } = props;
    const name = input.name;
    const value = input.value;
    setInformation({
        [name]: value,
    });
};
