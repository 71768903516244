//===================================================
// 1.Load,Init,Settings
//===================================================
import React from "react";

// Material-ui
import { Box } from "@mui/material";

// custom components
import { CompanyLogo } from "../../atoms";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const WithCenteredLogoTemplate = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store

  // showMain
  return showMain({
    ...props,
    classes,
  });
};

//===================================================
// 3.Export
//===================================================
export default WithCenteredLogoTemplate;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
WithCenteredLogoTemplate.propTypes = {};

//4-2. defaultProps
WithCenteredLogoTemplate.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { children } = props;
  return (
    <Box sx={{ p: 0, m: 0, width: "100%" }}>
      <CompanyLogo />
      {children}
    </Box>
  );
};

//===================================================
// 6.Actions
//===================================================
