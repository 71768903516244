//===================================================
// 1.Load,Init,Settings
//===================================================
import React from "react";

// Material-ui
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";

// custom components
import { CustomPhoneField } from "../../atoms";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const InformationForm = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store

  // showMain
  return showMain({
    ...props,
    classes,
  });
};

//===================================================
// 3.Export
//===================================================
export default InformationForm;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
InformationForm.propTypes = {};

//4-2. defaultProps
InformationForm.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const {
    classes,
    isLoading,
    hasError,
    setTermsAndPolicy,
    userInfo,
    disabled,
    handleAffiliateOpen
  } = props;


  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleOnSubmit(props);
      }}
    >
      <Box
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "15px",
          },
          borderRadius: "15px",
        }}
      >
        {/* <TextField
          error={hasError.email ? true : false}
          type="text"
          label="Preferred Email"
          variant="outlined"
          name="email"
          value={userInfo.email}
          onChange={(e) => handleInputChange(e.target, props)}
          sx={{ mb: 2, backgroundColor: "white", borderRadius: "15px" }}
          fullWidth
          helperText={hasError.email}
          inputProps={{ readOnly: disabled }}
        /> */}
      </Box>
      <Grid
        container
        rowSpacing={2}
        sx={{ p: 0 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {/* <Grid
          item
          xs={12}
          md={6}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "15px",
            },
            borderRadius: "15px",
          }}
        >
          <TextField
            error={hasError.firstName ? true : false}
            label="Firstname"
            variant="outlined"
            name="firstName"
            sx={{ backgroundColor: "white", borderRadius: "15px" }}
            value={userInfo.firstName}
            onChange={(e) => handleInputChange(e.target, props)}
            fullWidth
            helperText={hasError.firstName}
            inputProps={{ readOnly: disabled }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "15px",
            },
            borderRadius: "15px",
          }}
        >
          <TextField
            error={hasError.lastName ? true : false}
            label="Lastname"
            variant="outlined"
            name="lastName"
            sx={{ backgroundColor: "white", borderRadius: "15px" }}
            value={userInfo.lastName}
            onChange={(e) => handleInputChange(e.target, props)}
            fullWidth
            helperText={hasError.lastName}
            inputProps={{ readOnly: disabled }}
          />
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          md={12}
          sx={{
            "& .form-control ": {
              borderRadius: "15px",
            },
            borderRadius: "15px",
          }}
        >
          <CustomPhoneField
            setPhoneNumber={(val) =>
              handleInputChange({ name: "phone", value: val }, props)
            }
            hasError={hasError.phoneNumber}
            loginUserPhone={userInfo.phone}
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "15px",
            },
            borderRadius: "15px",
          }}
        >
          <TextField
            label="Leave us a note"
            variant="outlined"
            name="note"
            rows={5}
            multiline
            sx={{ backgroundColor: "white", borderRadius: "15px" }}
            value={userInfo.note}
            onChange={(e) => handleInputChange(e.target, props)}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
        >
          <Link
            onClick={handleAffiliateOpen}
            sx={{ cursor: 'pointer', ml: 1, color: "#00adef" }}>
            Ask for affiliate code
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "15px",
            },
            borderRadius: "15px",
          }}
        >
          <TextField
            label="Affiliate  Code"
            variant="outlined"
            name="affiliateCode"
            sx={{ backgroundColor: "white", borderRadius: "15px" }}
            value={userInfo.affiliateCode}
            onChange={(e) => handleInputChange(e.target, props)}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "15px",
            },
            borderRadius: "15px",
          }}
        >
          <TextField
            label="Promo  Code"
            variant="outlined"
            name="promoCode"
            sx={{ backgroundColor: "white", borderRadius: "15px" }}
            value={userInfo.promoCode}
            onChange={(e) => handleInputChange(e.target, props)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Container
        align="left"
        className={classes.container}
        maxWidth={false}
        sx={{ mt: 2 }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox onChange={(e) => setTermsAndPolicy(e.target.checked)} />
            }
            className={classes.customLabel}
            label={
              <React.Fragment>
                <Typography
                  variant="string"
                  sx={{ fontWeight: "bold", color: "#7c7c7c" }}
                >
                  I have read and accepted the{" "}
                </Typography>
                <Link
                  href="https://cleaningly.com.au/terms-conditions/"
                  target="_blank"
                  rel="noopener"
                  sx={{ color: "#00adef" }}
                >
                  Terms & Conditions{" "}
                </Link>
                <Typography
                  variant="string"
                  sx={{ fontWeight: "bold", color: "#7c7c7c" }}
                >
                  and{" "}
                </Typography>
                <Link
                  href="https://cleaningly.com.au/privacy-policy/"
                  target="_blank"
                  rel="noopener"
                  sx={{ color: "#00adef" }}
                >
                  Privacy Policy.{" "}
                </Link>
              </React.Fragment>
            }
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: {
                  xs: 8,
                  sm: 10,
                  md: 12,
                  lg: 14,
                  xl: 14,
                },
              },
            }}
          />
        </FormGroup>
        {hasError.agree ? (
          <Container align="left">
            <Typography variant="caption" color="#d32f2f" gutterBottom>
              {hasError.agree}
            </Typography>
          </Container>
        ) : null}

        {hasError.message ? (
          <Container align="left">
            <Typography variant="caption" color="#d32f2f" gutterBottom>
              {hasError.message}
            </Typography>
          </Container>
        ) : null}
      </Container>

      <Button
        type="submit"
        float="right"
        variant="contained"
        sx={{
          backgroundColor: "#ffb800",
          borderRadius: "40px",
          color: "#333",
          fontWeight: "bold",
          py: 2,
          mt: 2,
        }}
        disableElevation
        fullWidth
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress disableShrink={true} size={25} color="inherit" />
        ) : (
          "COMPLETE BOOKING"
        )}
      </Button>
    </form>
  );
};

//===================================================
// 6.Actions
//===================================================

/**
 * submit booking
 * @param {*} props
 */
const handleOnSubmit = (props) => {
  const { handleSubmitBooking } = props;
  handleSubmitBooking && handleSubmitBooking();
};

const handleInputChange = (input, props) => {
  const { setInformation } = props;
  const name = input.name;
  const value = input.value;
  setInformation({
    [name]: value,
  });
};
