//===================================================
// 1.Load,Init,Settings
//===================================================
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

// Material-ui
import { Container, Typography } from "@mui/material";

// custom components

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const CustomPhoneField = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const { loginUserPhone } = props;
  const [phoneValue, setPhoneValue] = useState(loginUserPhone ?? null);
  // showMain
  return showMain({
    ...props,
    classes,
    phoneValue,
    setPhoneValue,
  });
};

//===================================================
// 3.Export
//===================================================
export default CustomPhoneField;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
CustomPhoneField.propTypes = {};

//4-2. defaultProps
CustomPhoneField.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { classes, loginUserPhone, hasError, disabled } = props;
  return (
    <React.Fragment>
      <Container
        align="left"
        className={classes.container}
        maxWidth={false}
        sx={{
          "& .form-control ": {
            borderRadius: "15px",
          },
          "& .special-label": {
            borderRadius: "15px",
            border: "1px solid #CACACA",
          },
          borderRadius: "15px",
        }}
      >
        <PhoneInput
          className={hasError ? classes.hasError : ""}
          country={"au"}
          onlyCountries={["au"]}
          preferredCountries={["au"]}
          countryCodeEditable={false}
          disableDropdown={true}
          placeholder="+61 12 345 67089"
          masks={{ au: ".. ... ....." }}
          value={loginUserPhone}
          onChange={(e) => handleSetPhoneNumber(e, props)}
          inputProps={{
            name: "phone",
            required: true,
            readOnly: disabled,
          }}
        />
        {hasError ? (
          <Container align="left">
            <Typography variant="caption" color="#d32f2f" gutterBottom>
              {hasError}
            </Typography>
          </Container>
        ) : null}
      </Container>
    </React.Fragment>
  );
};

//===================================================
// 6.Actions
//===================================================

const handleSetPhoneNumber = (value, props) => {
  const { setPhoneValue, setPhoneNumber } = props;
  setPhoneValue(value);
  setPhoneNumber(`+${value}`);
};
