import React from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

// css *required
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomForm } from "../../atoms";
import { useStyles } from "./index.css";

const FormTest = () => {
  const classes = useStyles();
  const [bookingForm, setBookingForm] = useState(null);
  const [data, setData] = useState({});
  const [schema, setSchema] = useState(null);
  const [inputs, setInputs] = useState(null);
  const [uiSchema, setUiSchema] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const bookingForm = await axios.get("/api/booking-form/activated");
        setBookingForm(bookingForm.data.bookingForm);
        setSchema(JSON.parse(bookingForm.data.bookingForm.schema));
        setUiSchema(JSON.parse(bookingForm.data.bookingForm.uiSchema));
        setInputs(JSON.parse(bookingForm.data.bookingForm.inputs));
      } catch (error) {
        console.log(error);
      }
    };

    fetchInfo();
  }, []);

  const handleOnChange = ({ data, errors }) => {
    setData(data);
    setErrors(errors);
  };

  const submitData = useCallback(async () => {
    if (errors.length > 0) {
      setShowErrors(true);
      return;
    }

    const formattedData = {
      data: inputs?.map((i) => {
        let name = i?.name;
        if (i?.type === "Address") {
          name = String(name).concat("Address");
        }

        return { ...i, label: i?.label, value: data[name], name: i?.name };
      }),
      bookingFormId: bookingForm?._id,
    };

    try {
      const response = await axios.post("/api/booking-data", formattedData);
      console.log(response.data);
      setShowErrors(false);
      setErrors([]);
      navigate("/thankyou", { state: { bookingDataId: data?._id } });
    } catch (error) {
      console.log(error);
    }
  }, [data, bookingForm?._id, navigate, errors]);

  let dataKeys = Object.keys(data);

  const filteredInputs = inputs?.filter((i) => {
    const filter = ["bookingDate", "firstName", "lastName", "email", "phone"];
    let name = i?.name;
    if (i?.type === "Address") {
      name = String(name).concat("Address");
    }

    if (filter.indexOf(name) > -1) {
      return false;
    }

    return dataKeys.indexOf(name) > -1;
  });
  const summaryValues = filteredInputs?.map((i) => {
    let name = i?.name;
    if (i?.type === "Address") {
      name = String(name).concat("Address");
    }
    const objData = data[name];

    return { label: i?.label, value: objData };
  });

  return (
    <Container className={classes.fullWith} maxWidth={false}>
      <Grid container spacing={2} sx={{ px: "0 !important", mt: 2 }}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              {schema && uiSchema ? (
                <CustomForm
                  schema={schema}
                  uischema={uiSchema}
                  data={data}
                  onChange={handleOnChange}
                  validationMode={
                    showErrors ? "ValidateAndShow" : "ValidateAndHide"
                  }
                />
              ) : (
                "Loading...."
              )}
            </CardContent>
            <Box sx={{ p: 2 }}>
              <Button variant="contained" onClick={submitData}>
                Submit
              </Button>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Booking Summary
              </Typography>
              {data?.bookingDate && (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ fontSize: 14 }}
                    >
                      Booking Date
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ fontSize: 18 }}
                    >
                      {data?.bookingDate}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {summaryValues?.map((i) => (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ fontSize: 14 }}
                    >
                      {i?.label}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ fontSize: 16 }}
                    >
                      {i?.value}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
              <Divider variant="fullWidth" />
              {data?.fistName ||
              data?.lastName ||
              data?.email ||
              data?.phone ? (
                <Typography
                  gutterBottom
                  variant="h6"
                  color="text.secondary"
                  component="div"
                  sx={{ pt: 1 }}
                >
                  Client Details
                </Typography>
              ) : null}
              {data?.firstName && data?.lastName && (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ fontSize: 14 }}
                    >
                      Name
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ fontSize: 18 }}
                    >
                      {`${data?.firstName ? data?.firstName : ""} ${
                        data?.lastName ? data?.lastName : ""
                      }`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {data?.email && (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ fontSize: 14 }}
                    >
                      Email
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ fontSize: 18 }}
                    >
                      {data?.email}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {data?.phone && (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ fontSize: 14 }}
                    >
                      Phone Number
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ fontSize: 18 }}
                    >
                      {data?.phone}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FormTest;
