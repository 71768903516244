//===================================================
// 1.Load,Init,Settings
//===================================================
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import {
  checkPhoneNumber,
  toUpperCaseFirstLetter,
  validateValidEmail,
} from "../../../common/Utility";
import {
  GetOutsideRegisterActions,
  GetRegisterValue,
} from "../../../redux/modules/Register";

// Material-ui
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

// custom components
import {
  CustomPasswordInput,
  CustomPhoneField,
  CustomSearchLocation,
  ManualAddressForm,
} from "../../atoms";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const CreateAccountModal = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const { onClose, setSnackBar, userType } = props;
  const dispatch = useDispatch();
  const { isLoading } = GetRegisterValue();
  const [selectedAddress, setSelectedAddress] = useState({
    fullAddress: "",
    street: "",
    suburb: "",
    state: "",
    country: "Australia",
    zipcode: "",
    longitude: "",
    latitude: "",
  });
  // const [isLoading, setIsLoading] = useState(false);
  const [showManualAddress, setShowManualAddress] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [createAccountForm, setCreateAccountForm] = useState({
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    password: "",
    confirm_password: "",
    user_type: userType,
    street: "",
    zipcode: "",
    state: "",
    suburb: "",
    longitude: "",
    latitude: "",
    country: "Australia",
    fullAddress: "",
  });

  const resetForm = () => {
    setShowManualAddress(false);
    setCreateAccountForm({
      email: "",
      firstname: "",
      lastname: "",
      phone: "",
      password: "",
      confirm_password: "",
      user_type: userType,
      street: "",
      zipcode: "",
      state: "",
      suburb: "",
      fullAddress: "",
    });
    setIsSubmit(false);
    setFormErrors({});
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const submitForm = async () => {
        try {
          const { register } = GetOutsideRegisterActions();
          // const response = await axios.post("/api/users", createAccountForm);
          const response = await register(createAccountForm, dispatch);
          if (response.type === "register/failure") {
            const responseData = response.payload.error.response.data;
            if (responseData.message === "User already exist") {
              setFormErrors({ ...formErrors, email: "Email already exist." });
            }

            if (responseData.message === "Phone number already exist") {
              setFormErrors({
                ...formErrors,
                phoneNumber: "Phone number already exist.",
              });
            }
            return;
          }
          onClose && onClose();
          resetForm();
          setSnackBar({
            isShow: true,
            message: "Created account successfully",
            severity: "success",
          });
        } catch (error) {
          console.log(error);
        }
      };
      submitForm();
    }
  }, [formErrors, isSubmit]);

  // showMain
  return showMain({
    ...props,
    classes,
    selectedAddress,
    setSelectedAddress,
    showManualAddress,
    setShowManualAddress,
    createAccountForm,
    setCreateAccountForm,
    dispatch,
    formErrors,
    setFormErrors,
    isSubmit,
    setIsSubmit,
    userType,
    isLoading,
    // setIsLoading,
  });
};

//===================================================
// 3.Export
//===================================================
export default CreateAccountModal;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
CreateAccountModal.propTypes = {};

//4-2. defaultProps
CreateAccountModal.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const {
    classes,
    open,
    onClose,
    userType,
    showManualAddress,
    createAccountForm,
    setCreateAccountForm,
    setSelectedAddress,
    selectedAddress,
    isLoading,
    formErrors,
  } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle align="center">
        Create Account as {userType ? showUserType(props) : ""}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          rowSpacing={2}
          mt={1}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Grid
            item
            xs={12}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "15px",
              },
            }}
          >
            <TextField
              error={formErrors.email ? true : false}
              helperText={formErrors.email}
              type="email"
              name="email"
              sx={{ backgroundColor: "white", borderRadius: "15px" }}
              value={createAccountForm.email}
              onChange={(event) => {
                handleInputChange(event, props);
              }}
              label="Email"
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "15px",
              },
            }}
          >
            <TextField
              error={formErrors.firstname ? true : false}
              helperText={formErrors.firstname}
              type="text"
              sx={{ backgroundColor: "white", borderRadius: "15px" }}
              name="firstname"
              value={createAccountForm.firstname}
              onChange={(event) => {
                handleInputChange(event, props);
              }}
              label="Firstname"
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "15px",
              },
            }}
          >
            <TextField
              error={formErrors.lastname ? true : false}
              helperText={formErrors.lastname}
              type="text"
              name="lastname"
              sx={{ backgroundColor: "white", borderRadius: "15px" }}
              value={createAccountForm.lastname}
              onChange={(event) => {
                handleInputChange(event, props);
              }}
              label="Lastname"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} align="right">
            <Chip
              label={
                !showManualAddress ? "Add address manually" : "Search Address"
              }
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => handleShowManualAddress(props)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={
              (showManualAddress ? classes.hidden : null) +
              " " +
              classes.customPaddingTop
            }
          >
            <CustomSearchLocation
              setAddress={(address) =>
                setSelectedAddress({ ...selectedAddress, ...address })
              }
              hasError={formErrors.searchAddress}
              type="form"
            />
          </Grid>
          {showManualAddress ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.customPaddingTop}
            >
              <ManualAddressForm
                street={selectedAddress.street}
                suburb={selectedAddress.suburb}
                state={selectedAddress.state}
                zipcode={selectedAddress.zipcode}
                country={selectedAddress.country}
                setAddress={(address) =>
                  setSelectedAddress({ ...selectedAddress, ...address })
                }
                hasError={formErrors}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={12} md={12}>
            <CustomPhoneField
              setPhoneNumber={(val) =>
                setCreateAccountForm({ ...createAccountForm, phone: val })
              }
              hasError={formErrors.phoneNumber}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "15px",
              },
              zIndex: "1051 !important",
            }}
          >
            <CustomPasswordInput
              error={formErrors.password ? true : false}
              helperText={formErrors.password}
              name="password"
              value={createAccountForm.password}
              onChange={(event) => {
                handleInputChange(event, props);
              }}
              label="Password"
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "15px",
              },
              zIndex: "1051 !important",
            }}
          >
            <CustomPasswordInput
              error={formErrors.confirmPassword ? true : false}
              helperText={formErrors.confirmPassword}
              name="confirm_password"
              value={createAccountForm.confirm_password}
              onChange={(event) => {
                handleInputChange(event, props);
              }}
              label="Confirm Password"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          type="submit"
          variant="contained"
          onClick={() => {
            handleCreateAccount(props);
          }}
          fullWidth
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress disableShrink={true} size={25} color="inherit" />
          ) : (
            "Create Account"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * Show user type title
 * @param {*} props
 * @returns
 */
const showUserType = (props) => {
  const { userType } = props;
  return (
    <Typography display="inline" variant="h6" component="div" color="#1565c0">
      {userType === "cleaner"
        ? `${
            toUpperCaseFirstLetter("professional") +
            " " +
            toUpperCaseFirstLetter("cleaner")
          }`
        : toUpperCaseFirstLetter(userType)}
    </Typography>
  );
};

/**
 * Set address
 * @param {*} address
 * @param {*} props
 */
const handleSetAddress = (address, props) => {
  const { setCreateAccountForm, createAccountForm, showManualAddress } = props;
  if (showManualAddress) {
    setCreateAccountForm({ ...createAccountForm, ...address });
  }
};

/**
 * Validate form
 * @param {*} preparedData
 * @returns
 */
const validateForm = (preparedData, props) => {
  const { showManualAddress, selectedAddress } = props;
  const errors = {};
  if (!preparedData.email) {
    errors.email = "Please enter your email.";
  } else if (!validateValidEmail(preparedData.email)) {
    errors.email = "Please enter valid email.";
  }

  if (!preparedData.firstname) {
    errors.firstname = "Please enter your first name.";
  }

  if (!preparedData.lastname) {
    errors.lastname = "Please enter your last name.";
  }

  if (showManualAddress) {
    if (!selectedAddress.street) {
      errors.street = "Please enter street address.";
    }

    if (!selectedAddress.suburb) {
      errors.suburb = "Please enter a suburb.";
    }

    if (!selectedAddress.state) {
      errors.state = "Please enter a state.";
    }

    if (!selectedAddress.zipcode) {
      errors.zipcode = "Please enter a postcode.";
    } else if (
      !Number(selectedAddress.zipcode) ||
      selectedAddress.zipcode.length > 4
    ) {
      errors.zipcode = "Please enter a valid postcode.";
    }
  } else {
    if (!selectedAddress.fullAddress) {
      errors.searchAddress = "Please select an address";
    }
  }

  if (!preparedData.password) {
    errors.password = "Please enter a password.";
  } else if (preparedData.password.length < 8) {
    errors.password = "Use 8 characters or more for your password";
  }

  if (!preparedData.confirm_password) {
    errors.confirmPassword = "Please confirm your password.";
  } else if (preparedData.password !== preparedData.confirm_password) {
    errors.confirmPassword = "Password did not match.";
  }

  if (!checkPhoneNumber(preparedData.phone)) {
    errors.phoneNumber = "Please enter a valid phone number.";
  }

  return errors;
};

//===================================================
// 6.Actions
//===================================================

/**
 * handle input change
 * @param {*} event
 * @param {*} props
 */
const handleInputChange = (event, props) => {
  const { createAccountForm, setCreateAccountForm } = props;
  let name = event.target.name;
  let value = event.target.value;
  setCreateAccountForm({ ...createAccountForm, [name]: value });
};

/**
 * Create account
 * @param {*} props
 */
const handleCreateAccount = async (props) => {
  const {
    createAccountForm,
    setCreateAccountForm,
    setFormErrors,
    setIsSubmit,
    userType,
    selectedAddress,
  } = props;
  setFormErrors(validateForm(createAccountForm, props));
  setCreateAccountForm({
    ...createAccountForm,
    ...selectedAddress,
    user_type: userType,
  });
  setIsSubmit(true);
};

/**
 * handle show manual address
 * @param {*} props
 */
const handleShowManualAddress = (props) => {
  const { showManualAddress, setShowManualAddress, setSelectedAddress } = props;
  setSelectedAddress({
    fullAddress: "",
    street: "",
    suburb: "",
    state: "",
    country: "",
    zipcode: "",
    longitude: "",
    latitude: "",
  });
  setShowManualAddress(!showManualAddress);
};
