const STATE = [
  { short_name: "nsw", long_name: "New South Wales" },
  { short_name: "vic", long_name: "Victoria" },
  { short_name: "qld", long_name: "Queensland" },
  { short_name: "wa", long_name: "Western Australia" },
  { short_name: "sa", long_name: "South Australia" },
  { short_name: "tas", long_name: "Tasmania" },
  { short_name: "act", long_name: "Australian Capital Territory" },
  { short_name: "nt", long_name: "Northern Territory" },
];

const ACCEPT_BOOKING = "accepted";
const REJECT_BOOKING = "rejected";

const ASSIGNED_SUBURB_FOR_STATE = {
  nsw: "Sydney",
  vic: "Melbourne",
  act: "Canberra",
  qld: "Brisbabe",
};

const NUMBER_OF_HOURS = [3, 4, 5, 6, 7];
const NUMBER_OF_ROOMS = [3, 4, 5, 6, 7];
const NUMBER_OF_BATHROOMS = [1, 2, 3, 4, 5];

const RECURRING_SCHEDULE = ['Daily', 'Weekly', 'Fortnight', 'Monthly', 'Custom']

const END_OF_LEASE = [
  {
    num: 1,
    text: "1 Bedroom Studio ($550)",
    price: 550,
  },
  {
    num: 2,
    text: "2 Bedroom Studio ($660)",
    price: 660,
  },
  {
    num: 3,
    text: "3 Bedroom Studio ($880)",
    price: 880,
  },
  {
    num: 4,
    text: "4 Bedroom Studio ($1,000)",
    price: 1000,
  },
  {
    num: 5,
    text: "5 Bedroom Studio ($1,250)",
    price: 1250,
  },
];

const DEEP_CLEAN = [
  {
    num: 1,
    text: "1 Bedroom Studio ($550)",
    price: 550,
  },
  {
    num: 2,
    text: "2 Bedroom Studio ($660)",
    price: 660,
  },
  {
    num: 3,
    text: "3 Bedroom Studio ($880)",
    price: 880,
  },
  {
    num: 4,
    text: "4 Bedroom Studio ($1,000)",
    price: 1000,
  },
  {
    num: 5,
    text: "5 Bedroom Studio ($1,250)",
    price: 1250,
  },
];

const GENERAL_CLEANING = [
  // 'Clean Outside Floor +($50)', 'Clean Inside The Fridge +($50)',
  // 'Clean Inside The Cabinets +($50)', 'Clean Inside The Oven +($50)',
  // 'Clean Inside Closets +($50)', 'Clean Interior Windows +($50)',
  // 'Clean Interior Walls +($100)', 'Clean Load of Laundry +($50)'
  [
    { text: "Clean Outside Floor", price: 50 },
    { text: "Clean Inside The Fridge", price: 50 },
  ],
  [
    { text: "Clean Inside The Cabinets", price: 50 },
    { text: "Clean Inside The Oven", price: 50 },
  ],
  [
    { text: "Clean Inside Closets", price: 50 },
    { text: "Clean Interior Windows", price: 50 },
  ],
  [
    { text: "Clean Interior Walls", price: 100 },
    { text: "Clean Load of Laundry", price: 50 },
  ],
  [
    { text: "Clean inside & outside sliding door", price: 50 },
    { text: "Clean inside & outside windows ", price: 60 },
  ],
  [
    { text: "Carpet Steam Cleaning", price: 50 },
  ],
];

const BUSINESS_TYPE = [
  'Cafe Shops',
  'Clinics',
  'Government Agencies',
  'Gym',
  'Hospitals',
  'Hotels/Resorts',
  'NDIS Service Providers',
  'Office Spaces',
  'Rehab Facility',
  'Restaurant',
  'Retail Stores',
  'Schools',
  'Training Schools',
  'Warehouse',
  'Others'
]


export {
  STATE,
  RECURRING_SCHEDULE,
  NUMBER_OF_ROOMS,
  NUMBER_OF_BATHROOMS,
  ACCEPT_BOOKING,
  REJECT_BOOKING,
  ASSIGNED_SUBURB_FOR_STATE,
  END_OF_LEASE,
  DEEP_CLEAN,
  GENERAL_CLEANING,
  BUSINESS_TYPE
};
