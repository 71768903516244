// ** React Imports
import { useRef, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

// ** MUI Imports
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Container
} from "@mui/material";

const BookingChecklist = () => {
    const { id } = useParams();

    console.log('id', id)

    return (
        <Container sx={{ mt: 5 }}>
            <Card sx={{ px: { xs: "4", l: "15", } }} >
                <CardContent>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant="h6">Booking checklist</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container >
    )
};

export default BookingChecklist;