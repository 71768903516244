//===================================================
// 1.Load,Init,Settings
//===================================================

import React, { useState } from "react";

// Material-ui
import { IconButton, InputAdornment, TextField } from "@mui/material";

// Material-ui icon
import { Visibility, VisibilityOff } from "@mui/icons-material";

// custom components

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const CustomPasswordInput = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const [showPassword, setShowPassword] = useState(false);

  // showMain
  return showMain({
    ...props,
    classes,
    showPassword,
    setShowPassword,
  });
};

//===================================================
// 3.Export
//===================================================
export default CustomPasswordInput;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
CustomPasswordInput.propTypes = {};

//4-2. defaultProps
CustomPasswordInput.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { showPassword, setShowPassword, ...textFieldProps } = props;
  return (
    <TextField
      type={showPassword ? "text" : "password"}
      {...textFieldProps}
      sx={{ backgroundColor: "white", borderRadius: "15px" }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword(props)}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

//===================================================
// 6.Actions
//===================================================

/**
 * Show password
 * @param {*} props
 */
const handleClickShowPassword = (props) => {
  const { showPassword, setShowPassword } = props;
  setShowPassword(!showPassword);
};

/**
 *
 */
const handleMouseDownPassword = (event) => {
  event.preventDefault();
};
