// ** React Imports
import { useRef, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
// ** MUI Imports
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { Container } from "@mui/material";
import PaymentInfoModal from "../../atoms/PaymentInfoModal";

const convertDate = (date, isDueDate = false) => {
  const dateForConversion = new Date(date);
  if (isDueDate) {
    dateForConversion.setDate(dateForConversion.getDate() - 1);
  }
  const newDate = dateForConversion.toLocaleString("en-US", {
    month: "long",
    year: "numeric",
    day: "numeric",
  });
  return newDate;
};
const serverUrl = process.env.REACT_APP_BACKEND_URL;
axios.defaults.baseURL = serverUrl;

const ViewInvoce = () => {
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [openPayModal, setOpenPayModal] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleOpenPayModal = () => {
    setOpenPayModal(true);
  };

  const handleClosePayModal = () => {
    setOpenPayModal(false);
  };

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const companyRes = await axios.get("/api/company/public");
        setCompanyInfo(companyRes.data.company);
        const response = await axios.get(`/api/invoice/public/${id}`);
        const cleanedResponse = {
          ...response?.data.invoice,
          id: response?.data.invoice._id,
        };
        console.log('cleanedResponse', cleanedResponse)
        setData(cleanedResponse);
      } catch (error) {
        console.log(error);
      }
    };

    fetchInfo();
  }, [id]);

  const handlePayRedirect = async () => {
    setLoading(true);
    const { data } = await axios.get("/api/stripe/create-checkout/" + id);
    setLoading(false);
    window.location.href = data?.redirect_url;
  };

  const convertFromJson = (text) => {
    try {
      let fullAddOns = "";
      const stringified = JSON.parse(text);

      if (stringified.length > 0) {
        stringified.forEach((item) => {
          Object.entries(item).forEach(([key, value]) => {
            fullAddOns += `, ${key === "price" ? "price: AUD" : ""} ${value}`;
          });
          if (fullAddOns.charAt(0) === ",") {
            fullAddOns = fullAddOns.substring(1);
          }
        });
      } else {
        Object.entries(stringified).forEach(([key, value]) => {
          fullAddOns += `, ${key}: ${value}`;
        });

        if (fullAddOns.charAt(0) === ",") {
          fullAddOns = fullAddOns.substring(1);
        }
      }

      return fullAddOns;
    } catch (error) {
      console.log(error);
      let fullAddOns = "";

      if (text.length > 0) {
        text.forEach((item) => {
          Object.entries(item).forEach(([key, value]) => {
            fullAddOns += `, ${key === "text" ? "task" : "price"}: ${value}`;
          });
          if (fullAddOns.charAt(0) === ",") {
            fullAddOns = fullAddOns.substring(1);
          }
        });
      } else {
        Object.entries(text).forEach(([key, value]) => {
          fullAddOns += `, ${key}: ${value}`;
        });

        if (fullAddOns.charAt(0) === ",") {
          fullAddOns = fullAddOns.substring(1);
        }
      }

      return fullAddOns;
    }
  };

  if (data) {
    const addOns = [];
    let addOnsPrice = 0;
    let subtotal;
    let booking = false;

    const dataAddOns = (data?.bookingId) ? data?.bookingId.addOns : data?.addOns;

    let text = convertFromJson(dataAddOns);

    if (convertFromJson(dataAddOns).includes("price")) {
      booking = true;
      text = convertFromJson(dataAddOns).match(/[^,]+,[^,]+/g);
    }

    if (booking) {
      text.map((e) => {
        const items = e.trim().split(", ");
        const price = items[1].split(":")[1].split(" ")[2];
        addOnsPrice += Number(price);
        addOns.push({ name: items[0], price });
      });
    } else {
      text.split(",").map((e) => {
        if (e.split(":").length > 1) {
          addOns.push({
            name: e.split(":")[0].trim(),
            price: Number(e.split(":")[1].trim()),
          });
          addOnsPrice += Number(e.split(":")[1].trim());
        }
      });
    }
    const calculateGST = (totalPrice) => {
      const totalPercentage = (Number(totalPrice) / 110).toFixed(2); // 1%
      const calSubtotal = (Number(totalPercentage) * 100).toFixed(2); // Total amount without gst including addons
      const calGSTPrice = (calSubtotal * 0.1).toFixed(2); // GST amount
      const calGeneralPrice = (Number(calSubtotal) - addOnsPrice).toFixed(2);

      return { calGSTPrice, calGeneralPrice, calSubtotal }
    }

    const totalPrice = (data.bookingId) ? Number(data.bookingId?.total.replace("$", "")) : Number(data.totalPrice.replace("$", ""));
    const isPaid = data?.bookingId?.isPaid;

    let gstPrice = 0;
    let generalPrice = 0;
    console.log('data', data)
    if (!data.bookingId) {
      if (data.isGst) {

        const { calGSTPrice, calGeneralPrice, calSubtotal } = calculateGST(totalPrice);
        gstPrice = calGSTPrice;
        generalPrice = calGeneralPrice;
        subtotal = calSubtotal;
      } else {
        subtotal = (Number(totalPrice)).toFixed(2);
        gstPrice = '0.00'; // GST amount
        generalPrice = (Number(totalPrice) - addOnsPrice).toFixed(2);
      }
    } else {
      if (data.bookingId?.bookingFrom === 'console') {
        if (data.bookingId?.hasGST) {
          const { calGSTPrice, calGeneralPrice, calSubtotal } = calculateGST(totalPrice);
          gstPrice = calGSTPrice;
          generalPrice = calGeneralPrice;
          subtotal = calSubtotal;

        } else {
          subtotal = (Number(totalPrice)).toFixed(2);
          gstPrice = '0.00'; // GST amount
          generalPrice = (Number(totalPrice) - addOnsPrice).toFixed(2);
        }

      } else {
        const { calGSTPrice, calGeneralPrice, calSubtotal } = calculateGST(totalPrice);
        gstPrice = calGSTPrice;
        generalPrice = calGeneralPrice;
        subtotal = calSubtotal;
      }
    }

    const parseService = (service) => {

      if (service) {
        let services = '';
        try {
          services = JSON.parse(service);
        } catch (e) {
          services = service.split(',');
        }
        return [].concat(
          ...services.map((s) => (
            <p>
              {(s.service) ? s.service : s}
            </p>
          ))
        );
      }
    }

    return (
      <Container>
        {openPayModal && (
          <PaymentInfoModal
            open={openPayModal}
            handleClose={handleClosePayModal}
            totalPrice={Number(Number(subtotal) + Number(gstPrice)).toFixed(2)}
          />
        )}
        <Card
          sx={{
            px: {
              xs: "4",
              l: "15",
            },
          }}
        >
          <CardContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">TAX INVOICE</Typography>
                <img
                  src={companyInfo?.companyLogoUrl}
                  height="150px"
                  width="150px"
                  alt="cleaningly-logo"
                  style={{
                    objectFit: "contain",
                    objectPosition: "center",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
            </Grid>
            <Divider light={false} sx={{ mb: 4 }} />
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      To:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{data?.fullName}</Typography>
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">{data?.fullAddress}</Typography>
                  </Grid>
                  <Grid item xs={6} mt={4}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Invoice Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} mt={4}>
                    <Typography variant="body2">{`INV-${data?.invoiceNumber}`}</Typography>
                  </Grid>
                  <Grid item xs={6} mt={4}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Issued:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} mt={4}>
                    <Typography variant="body2">
                      {data.bookingDate ? convertDate(data.createdAt, false) : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} mt={4}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Due:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} mt={4}>
                    <Typography variant="body2">
                      {data.bookingDate ? convertDate(data.bookingDate, true) : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container sx={{ pl: { xs: "0", lg: 4 } }}>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      From
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        {companyInfo?.companyName}
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        {companyInfo?.street}, {companyInfo?.suburb}
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        {companyInfo?.zipcode}, {companyInfo?.state},{" "}
                        {companyInfo?.country}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} mt={4}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      ABN:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} mt={4}>
                    <Typography variant="body2">
                      {companyInfo?.abn ? companyInfo?.abn : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider light={false} sx={{ my: 4 }} />

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontWeight: "bold" }}>QTY</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontWeight: "bold" }}>Total</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <p>Professional Cleaning Service</p>
                      <p>{parseService((data.bookingId) ? data.bookingId?.service : data.service)}</p>
                      <p>{data?.description}</p>
                    </TableCell>
                    <TableCell>{(data.bookingId) ? data.bookingId?.numberOfHours : data.numberOfHours}</TableCell>
                    <TableCell>${generalPrice}</TableCell>
                  </TableRow>
                  {addOns.map((item) => (
                    <TableRow key={item.name}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>1</TableCell>
                      <TableCell>${item.price}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={1} sx={{ borderBottom: "none" }} >
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Subtotal:
                      </Typography>
                    </TableCell>
                    <TableCell>${subtotal}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1} sx={{ borderBottom: "none" }} >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Make all payable to:
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Total GST 10%:
                      </Typography>
                    </TableCell>
                    <TableCell>${gstPrice}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1} sx={{ borderBottom: "none" }} >
                      <TableRow>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Account Name : Cleaningly Home Services Pty Ltd
                        </Typography>
                      </TableRow>
                      <TableRow>
                        <Typography sx={{ fontWeight: "bold" }}>
                          BSB : 013598
                        </Typography>
                      </TableRow>
                      <TableRow>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          Account Number : 642964741
                        </Typography>
                      </TableRow>
                    </TableCell>
                    <TableCell colSpan={1} sx={{ fontWeight: "bold" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Amount Due:
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontWeight: "bold" }}>
                        $
                        {/* {Number(Number(subtotal) + Number(gstPrice)).toFixed(2)} */}
                        {totalPrice.toFixed(2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1} sx={{ borderBottom: "none" }} ></TableCell>
                    <TableCell colSpan={1} sx={{ fontWeight: "bold" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Payment Status:
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {isPaid ? (
                        <Typography sx={{ fontWeight: "bold" }} color="green">
                          Paid
                        </Typography>
                      ) : (
                        <Button
                          disabled={loading}
                          variant="contained"
                          sx={{ fontWeight: "bold" }}
                          onClick={handlePayRedirect}
                        >
                          {loading ? "Loading..." : "Click to Pay"}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
    );
  }
  return null;
};

export default ViewInvoce;
